import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MaterialThemeModule } from './material-theme/material-theme.module';
import { BusyIndicatorService } from './services/busy-indicator.service';
import { SnackBarService } from './services/snackbar.service';
import { TransomApiClientService } from './services/transom-api-client/transom-api-client.service';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorTx } from './mat-paginator-tx/mat-paginator-tx';
import {
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import 'quill-mention';
import { Mentions } from './utils/mentions';
// import 'format';
// import 'quill';

const socketIoConfig: SocketIoConfig = {
  url: environment.API_BASE,
  // url: 'http://localhost:7090',
  options: { autoConnect: false },
};

const translateModuleConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
  defaultLanguage: 'en',
};

// AoT requires an exported function for factories
export function AppInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return async () => {
    const lang = translate.getBrowserLang() || 'en';
    try {
      await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      translate.setDefaultLang(lang);
      await translate.use(lang).toPromise();
      console.log(`Loaded language: ${lang}`);
    } catch (err) {
      console.error(`Error loading: ${lang}`, err);
    }
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'assets/i18n/',
    `.json?t=${new Date().getTime()}`
  );
}

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialThemeModule,
    HttpClientModule,
    SocketIoModule.forRoot(socketIoConfig),
    NgxWebstorageModule.forRoot(),
    SocialLoginModule,
    PopoverModule.forRoot(),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    QuillModule.forRoot({
      modules: {
        mention: {
          showDenotationChar: false,
          mentionDenotationChars: ['{{', '['],
          source: function (searchTerm, renderList, mentionChar) {
            let values;
            if (mentionChar === '{{') {
              values = Mentions.mustacheTags();
            } else {
              values = [
                // { id: 3, value: 'Fredrik Sundqvist hello="3"]' },
                // { id: 4, value: 'Patrik Sjölin hello="2"]' },
              ];
            }

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                const found =
                  values[i].value
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) > -1;
                if (found) {
                  matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          },
        },
        // formats: [
        //   'background',
        //   'bold',
        //   'color',
        //   'font',
        //   'italic',
        //   'link',
        //   'size',
        //   'strike',
        //   'script',
        //   'underline',
        //   'blockquote',
        //   'header',
        //   'indent',
        //   'list',
        //   'align',
        //   'direction',
        //   'code-block',
        //   'image',
        // ],
        toolbar: [
          [{ header: [1, 2, 3, false] }], // 4, 5, 6,
          [{ size: ['small', false, 'large'] }], //, 'huge'
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'], //, 'code-block'
          // [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          // [{ direction: 'rtl' }],
          // [
          //     { color: [] },
          //     { background: [] }
          // ],
          [{ font: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image'], // ,'video'
        ],
      },
    }),
    TranslateModule.forRoot(translateModuleConfig),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorTx,
      deps: [TranslateService, Injector],
    },
    BusyIndicatorService,
    SnackBarService,
    TransomApiClientService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('clientId'),
          // },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider('clientId'),
          // },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
