<mat-card class="shipping-card">
    <mat-card-header> <mat-card-title>BOS Advance</mat-card-title> </mat-card-header>
  
    <mat-card-content>
      <div class="logo-align-center">
        <div mat-card-avatar class="app-logo"></div>
        <mat-card-actions>
          <a class="is-clickable" (click)="signInWithGoogle()">
            <img src="/assets/google/1x/btn_google_signin_dark_normal_web.png" />
          </a>
        </mat-card-actions>
      </div>
    </mat-card-content>
    <!-- <img mat-card-image class="app-logo" src="assets/flipper.jpg" /> -->
  </mat-card>
  
  <!-- 
  <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-xs-10 col-md-6 col-lg-4">
  
              <mat-card class="login-card">
                  <mat-card-header>
                    <mat-card-title class="login-title">Login</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                      <div class="row">
                          <div class="col" class="login-help-text">
                              Please login with your <strong>LogMonster.net</strong> credentials.
                          </div>
                        </div>
      
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input matInput placeholder="Email" formControlName="username" autocomplete="email">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input matInput type="password" placeholder="Password" formControlName="password" autocomplete="current-password">
                          <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                            Password is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                          
                  </mat-card-content>
                  <mat-card-actions align="end">
                    <button mat-raised-button color="primary" type="submit">Submit</button>
                  </mat-card-actions>
  
                  <mat-card-footer>
                    <div class="row">
                      <div class="col">
                        Not registered?<br>
                        <a [routerLink]="['/register']">Create an Account</a>
                      </div>
                      <div class="col" align="end">
                        <br>
                        <a [routerLink]="['/forgot']">Forgot password?</a>
                      </div>
                    </div>
  
                    <a class="is-clickable" (click)="signInWithGithub()">
                      github
                    </a>
                    <a class="is-clickable" (click)="signInWithGoogle()">
                      google
                    </a>
            
                  </mat-card-footer>
                </mat-card>
              
              </div>
            </div>
          </div>
        </form> -->
  