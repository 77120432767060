import { Injectable } from "@angular/core";
import { distinctUntilChanged } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BusyIndicatorService {
  private isBusySubject$ = new BehaviorSubject<boolean>(false);

  // Used by the busyIndicator component to show a "spinner" dialog.
  public isBusy$ = this.isBusySubject$.asObservable().pipe(distinctUntilChanged());

  constructor() {}

  show() {
    this.isBusySubject$.next(true);
  }

  hide() {
    this.isBusySubject$.next(false);
  }
}
