import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TransomApiClientService } from '../services/transom-api-client/transom-api-client.service';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { filter, tap, take, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = this.fb.group({
    username: null,
    password: [null, Validators.required],
  });
  authProvider: string;
  subscriptions: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiClient: TransomApiClientService,
    private authService: SocialAuthService,
    private router: Router,
    // private snackBar: MatSnackBar
  ) {
    this.subscriptions = new Subscription();
  }

  // constructor(private authService: SocialAuthService) { }

  ngOnInit() {
    // Don't let Logged-in Users navigate back to the Login page!
    this.apiClient.currentUser
      .pipe(
        take(1),
        filter((user) => !!user)
      )
      .subscribe(() => {
        this.router.navigate(['']);
      });

    // Subscribe to the Auth State so we can authenticate on the REST API
    const sub = this.authService.authState
      .pipe(
        tap((x) => {
          console.log('authState::', x, this.authProvider);
        }),
        mergeMap((user) => {
          if (user && this.authProvider === 'google') {
            return this.apiClient.validateGoogleLogin();
          }
          return of(false);
        }),
        mergeMap((result) => {
          if (!result) {
            return of({});
          }
          // Execute userMe to get a copy of the API user in the apiClient.
          return this.apiClient.userMe();
          // }),
          // catchError((err)=> {
          //   console.log('Google Login Error:', err);
          //   this.apiClient.currentUser.next(false);
        })
      )
      .subscribe();
    this.subscriptions.add(sub);

    // const subA = this.authService.initState.pipe().subscribe(value => {
    //   console.log('initState:', value); // handy debug.
    // });
    // this.subscriptions.add(subA);

    // const subB = this.authService.authState.subscribe(value => {
    //   console.log('authState:', value); // handy debug.
    // });
    // this.subscriptions.add(subB);
  }

  signInWithGoogle(): void {
    this.authProvider = 'google';

    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((result) => {
        console.log('G-login:', result);
        this.router.navigate(['']);
      })
      .catch((err) => {
        // google handles the incorrect credentials portion of the login flow.
        // here we just get an error indicating that the user abandoned the login process
        // error quietly and remain on the login page.
        console.log(err);
      });
  }

  // signInWithFacebook(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  // signInWithGithub() {
  //   console.log("github login...");
  // }

  signOut(): void {
    this.authProvider = null;
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
