<mat-form-field *ngIf="inputType == 'number'" class="bos-number-field">
  <input matInput  type="number" autocomplete="off" [placeholder]="placeholder" [required]="required" [formControl]="control"  />
  <span matPrefix>
    <ng-container *ngTemplateOutlet="inputPrefix"></ng-container>
  </span>
  <span matSuffix>
    <ng-container *ngTemplateOutlet="inputSuffix"></ng-container>
  </span>
  <mat-error [ngClass]="isInvalid() ? null : 'tip'">
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="inputType == 'readonly'" class="bos-readonly-field">
  <input matInput  type="text" tabindex="-1" readonly [placeholder]="placeholder" [required]="required" [formControl]="control" />
  <span matPrefix>
    <ng-container *ngTemplateOutlet="inputPrefix"></ng-container>
  </span>
  <span matSuffix>
    <ng-container *ngTemplateOutlet="inputSuffix"></ng-container>
  </span>
  <mat-error [ngClass]="isInvalid() ? null : 'tip'">
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="inputType == 'input' && !autoComplete" >
  <input matInput  [placeholder]="placeholder" [required]="required" [formControl]="control" />
  <!-- <button mat-button *ngIf="showClear && control && control.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue()">
    <mat-icon>close</mat-icon>
  </button> -->
  <mat-error [ngClass]="isInvalid() ? null : 'tip'">
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="inputType == 'input' && autoComplete" >
  <input matInput [placeholder]="placeholder" [required]="required" [formControl]="control" (optionSelected)="handleChange($event)" [matAutocomplete]="autoComplete" />
  <button mat-button *ngIf="showClear && control && control.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error [ngClass]="isInvalid() ? null : 'tip'">
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="inputType == 'date'" >
  <input matInput [matDatepicker]="picker" autocomplete="off" [placeholder]="placeholder" [required]="required" [formControl]="control" (dateChange)="handleChange($event)"/>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error [ngClass]="isInvalid() ? null : 'tip'">
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<mat-form-field *ngIf="inputType == 'textarea'">
  <textarea matInput [placeholder]="placeholder" [rows]="rows" [required]="required" [formControl]="control" ></textarea>
  <mat-error>
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>


<mat-form-field *ngIf="inputType.indexOf('select') > -1">
  <mat-select [multiple]="inputType == 'multiselect'" [placeholder]="placeholder" [required]="required" [formControl]="control" (selectionChange)="handleChange($event)">
    <mat-option *ngFor="let item of options" [value]="item[optionValue]">
      {{ item[optionDisplay] }}
    </mat-option>
  </mat-select>
  <mat-error>
    <ng-container *ngTemplateOutlet="valErrors"></ng-container>
  </mat-error>
  <mat-hint *ngIf="showTip()">
    <ng-container *ngTemplateOutlet="inputHint"></ng-container>
  </mat-hint>
</mat-form-field>

<ng-template #valErrors>
  <!-- Make sure all uncommented ones have a matching variables in ValidationMessages. -->
  <ng-container *ngIf="validationErrors('min')">{{ dynamicMsg("min") }}</ng-container>
  <ng-container *ngIf="validationErrors('max')">{{ dynamicMsg("max") }}</ng-container>
  <ng-container *ngIf="validationErrors('Mask error')">{{ dynamicMsg("mask") }}</ng-container>
  <ng-container *ngIf="validationErrors('required')">{{ dynamicMsg("required") }}</ng-container>
  <!-- <ng-container *ngIf="validationErrors('requiredTrue')">{{ dynamicMsg('requiredTrue') }}</ng-container> -->
  <!-- <ng-container *ngIf="validationErrors('email')">{{ dynamicMsg("email") }}</ng-container> -->
  <ng-container *ngIf="validationErrors('minlength')">{{ dynamicMsg("minlength") }}</ng-container>
  <ng-container *ngIf="validationErrors('maxlength')">{{ dynamicMsg("maxlength") }}</ng-container>
  <ng-container *ngIf="validationErrors('onlyFuture')">{{ dynamicMsg("onlyFuture") }}</ng-container>
  <ng-container *ngIf="validationErrors('noDup')">{{ dynamicMsg("noDup") }}</ng-container>
  <ng-container *ngIf="validationErrors('dupTemplate')">{{ dynamicMsg("dupTemplate") }}</ng-container>
  <ng-container *ngIf="validationErrors('toDate')">{{ dynamicMsg("toDate") }}</ng-container>
  <ng-container *ngIf="validationErrors('email')">{{ dynamicMsg("email") }}</ng-container>
  <ng-container *ngIf="validationErrors('validDate')">{{ dynamicMsg("validDate") }}</ng-container>
  <ng-container *ngIf="validationErrors('pattern')">{{ dynamicMsg("pattern") }}</ng-container>
  <ng-container *ngIf="validationErrors('noFuture')">{{ dynamicMsg("noFuture") }}</ng-container>
  <ng-container *ngIf="validationErrors('validSIN')">{{ dynamicMsg("validSIN") }}</ng-container>
  <ng-container *ngIf="validationErrors('lessthan')">{{ dynamicMsg("lessthan") }}</ng-container>
</ng-template>

<ng-template #inputHint>
  <ng-container *ngIf="showTip()">{{ dynamicMsg("tip") }}</ng-container>
</ng-template>

<ng-template #inputPrefix>
  <ng-container *ngIf="prefix">{{ prefix }}</ng-container>
</ng-template>

<ng-template #inputSuffix>
  <ng-container *ngIf="suffix">{{ suffix }}</ng-container>
</ng-template>

