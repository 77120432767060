import { Pipe, PipeTransform } from '@angular/core';

/** 
 * Example Usage within an Angular component: 
 * 
 * <h1>{{person.email | link }}</h1> 
 * <!-- Outputs: <a href="mailto:jim@home.com">jim@home.com</a> -->
 * 
 * <h1>{{person.email | link :'mailto' }}</h1> 
 * <!-- Outputs: <a href="mailto:jim@home.com">jim@home.com</a> -->
 * 
 * <h1>{{person.phone | link :'tel' }}</h1> 
 * <!-- Outputs: <a href="tel:555-1234">555-1234</a> -->
 * 
 * <h1>{{person.webiste | link :'href' }}</h1> 
 * <!-- Outputs: <a href="http://www.home.com">http://www.home.com</a> -->
 * 
 * Usage:
 * 
 *  <span [innerHTML]="row.email | link : 'mailto'"></span>
 * 
 */
@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  transform(value: string, type = 'href', target="_blank", title="") {
    if (value == null || value.length < 5) {
      return value;
    }
    if (type  === 'href') {
      return `<a href="${value}" target="${target}" title="${title}">${value}</a>`;
    }
    return `<a href="${type}:${value}" target="${target}" title="${title}">${value}</a>`;
  }
}
