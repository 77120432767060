import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  option: DialogOption;
}

export interface DialogOption {
  checked: number;
  message: string;
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string = 'Confirm';
  message: string = 'Ask a question';
  option: DialogOption

  
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.message = this.data.message || this.message;
    this.title = this.data.title || this.title;
    this.option = this.data.option;
  }

  onCancel(){
    this.dialogRef.close(false);
  }

  onOK(){
    this.dialogRef.close({
      option: this.option
    });
  }
}
