import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
// import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizedTasks } from '../../models/authorizedTasks';
import { User } from '../../models/user';

declare var gapi;

// const API_TOKEN = 'TransomApiAuthToken';
// const CURRENT_USER = 'TransomApiCurrentUser';
// const AUTHORIZATION = 'authorization';

/**
 * Generic implementation of calls to the API. It supports making
 * CRUD style calls and calls to custom back-end functions.
 *
 * Each call response is routed through a centralized response handler
 * to facilitate error handling in the application.
 * E.g. Handling authentication errors from expired tokens can be
 *      implemented in one place.
 *
 * This API client is the authentication service for the API back-end, adding the
 * necessary headers.  It implements the canActivate interface for the route guards.
 */
@Injectable({
  providedIn: 'root',
})
export class TransomApiClientService implements CanActivate {
  public baseUrl: string;
  public currentUser: BehaviorSubject<User | boolean>;
  private authUser: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    // public socket: Socket,
    private authService: SocialAuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.baseUrl = environment.API_BASEURL;
    this.currentUser = new BehaviorSubject<User | boolean>(null);
    this.authUser = new BehaviorSubject<any>(null);

    // socket.on('documents', (data) => {
    //   // Add new messages to the top of the list
    //   console.log("documents:", data);
    // });

    // socket.on('error', (err) => {
    //   console.log('Socket error:', err);

    //   setTimeout(() => {
    //     // Attempt reconnect but only if we have a valid user!
    //     this.currentUser.pipe(take(1)).subscribe((user) => {
    //       // if (!!user) {
    //       //   this.connectSocket();
    //       // }
    //     });
    //   }, 500);
    // });

    this.authService.initState.subscribe((init) => {});

    this.authService.authState
      .pipe(
        tap((user) => {
          if (user) {
            this.authUser.next(user);
            this.userMe();
          } else {
            this.authUser.next(false);
          }
        })
      )
      .subscribe();

    this.currentUser.subscribe((user) => {
      // Cleanup headers and local storage when a user is logged out.
      if (user === false) {
        console.log('Cleanup headers and local storage, user is logged out.');
        // this.socket.disconnect();
        this.currentUser.next(null);
      }
      // No need for Sockets yet.
      // if (!!user) {
      //   this.connectSocket();
      // }
    });
  }

  // connectSocket() {
  //   console.log('Connecting socket...');
  //   const url = `${this.baseUrl}/user/sockettoken`;
  //
  //   this.getBearerHeaders()
  //     .pipe(
  //       mergeMap((headers) => {
  //         return this.http.get(url, headers);
  //       }),
  //       take(1),
  //       tap((response: any) => {
  //         this.socket.ioSocket.io.opts.query = {
  //           token: response.token,
  //         };
  //         this.socket.connect();
  //       }),
  //       catchError((err: HttpErrorResponse) => {
  //         console.error('ERROR:', err);
  //         return throwError(err.message || 'server error.');
  //       })
  //     )
  //     .subscribe();
  // }

  /**
   * Used on the route guard. Prevents anonymous access to routes that require a login.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authorizedTask(route.data.task).pipe(
      map((val) => {
        if (!val) {
          this.router.navigateByUrl('/unauthorized');
        }
        return val;
      })
    );
  }

  authorizedTask(taskName: AuthorizedTasks): Observable<boolean> {
    return this.currentUser.pipe(
      filter((user) => !!user),
      take(1),
      map((user: User) => (user.authorizedTasks || []).indexOf(taskName) > -1)
    );
  }

  /**
   * During login and logout we are setting a new state, so the currentUser
   * is triggered with the new value and the application state can be updated.
   *
   * @param newUser The newly acquired user profile, or null.
   */
  setLoginState(newUser: User): void {
    if (newUser) {
      const currUser: any = this.currentUser.valueOf() || { id: 0 };
      if (currUser._id !== newUser.id) {
        // this.storage.store(CURRENT_USER, newUser);
        this.currentUser.next(newUser);
      }
    } else {
      this.currentUser.next(false);
    }
  }

  getIdHeaders() {
    return this.authService.authState.pipe(
      take(1),
      map((currentUser) => {
        return {
          headers: {
            authorization: `${currentUser.idToken}`,
          },
        };
      })
    );
  }

  getBearerHeaders(options?: any) {
    return this.authUser.pipe(
      filter((u) => u !== null),
      take(1),
      map((currentUser) => {
        if (currentUser) {
          const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse()
            .access_token;
          return Object.assign(
            {},
            {
              headers: { authorization: `Bearer ${accessToken}` },
            },
            options
          );
        }
        return options;
      })
    );
  }

  getBearerHeadersOld(options?: any) {
    return this.authService.authState.pipe(
      take(1),
      map((currentUser) => {
        if (currentUser) {
          return Object.assign(
            {},
            {
              headers: { authorization: `Bearer ${currentUser.authToken}` },
            },
            options
          );
        }
        return options;
      })
    );
  }

  /**
   * Generic API response handler. Passes the response on to the orginal caller
   * and catches any errors as needed using the catchError operator (rxjs).
   *
   * @param responseObs The generic response Observable of any API call
   */
  handleResponse(responseObs: Observable<any>): Observable<any> {
    return responseObs.pipe(
      take(1),
      map((res: Response) => {
        let retval: any = res;

        // Results that include multiple documemts are packaged in an envelope with a data property.
        if (retval.data) {
          retval = retval.data;
        }
        return retval;
      }),
      catchError((err: HttpErrorResponse, caught: Observable<any>) => {
        return this.handleHttpError(err, caught);
      })
    );
  }

  /**
   * The implementation of general error handling is fairly rudimentary. Any 401 response is
   * assumed to mean that the token is expired and we're not logged in anymore.
   * (Valid in this particular app, but would need to be evaluated if developed further).
   * Any connectivity error is thrown as a hard error, as there is no graceful handling to operate in a disconnected state.
   *
   * The application does not (yet) provide a means for notifying the user of generic errors (E.g. drawer or toast)
   * therefore those errors are simply logged to the console.
   *
   * @param error the error response
   * @param responseObs the caught Observable with the error.
   */
  handleHttpError(error: HttpErrorResponse, responseObs?: Observable<any>) {
    const snackBarOptions = {
      duration: 6000,
    };
    if (error.status === 401) {
      this.setLoginState(null);
    } else if (error.status === 0) {
      this.snackBar.open('The api is not available at the moment', 'Close', snackBarOptions);
      // Not connected, note CORS errors end up here as well!
      throw error;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body contains details what went wrong,
      let msg: string;
      if (error.error && error.error.message) {
        msg = this.getUserReadableErrorMessage(error.error.message);
      } else {
        msg = error.message || "That didn't work!";
      }
      this.snackBar.open(msg, 'Close', snackBarOptions);
      throw error;
    }
    return of([]);
  }

  /**
   *
   * @param tokenObj
   */
  getUserReadableErrorMessage(msg): string {
    let ret = msg;
    if (msg.indexOf('SequelizeUniqueConstraintError') >= 0) {
      ret = 'Duplicate record, Unable to insert/update.';
    }
    //msg => Error executing deal modelInsert(); caused by BadRequestError: Please start a new deal from 20337-6J7O-01
    var myRegexp = /caused by(.*)Error: (.*)/;
    const regParts = myRegexp.exec(msg);
    if (regParts) {
      ret = regParts[2];
    }
    return ret;
  }

  validateGoogleLogin(): Observable<boolean> {
    return this.getIdHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(`${this.baseUrl}/user/google/login`, {}, headers);
      }),
      take(1),
      map((response) => {
        console.log('Google validation success:', response);
        return true;
      }),
      catchError((err) => {
        console.log('Google validation error:', err);
        return of(false);
      })
    );
  }

  googleLogout() {
    return this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(`${this.baseUrl}/user/google/logout`, {}, headers);
      }),
      take(1),
      tap(() => {
        const revoke = true; // revoke or not?!!
        this.authService.signOut(revoke);
        this.setLoginState(null);
        return true;
      }),
      catchError((err) => {
        console.log('Google logout error:', err);
        return of(false);
      })
    );
  }

  /**
   * Makes an authenticated request that returns the current user profile from the back end API.
   * It is used in the application as the final step of a successful login, to get the user's profile
   * data and manage the login state.
   */
  userMe(): Observable<User> {
    return this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.get(`${this.baseUrl}/user/me`, headers);
      }),
      take(1),
      map((reply: any) => {
        const user = reply;
        this.setLoginState(user);
        return user;
      }),
      catchError((err) => {
        if (err.status === 401) {
          // User is not logged in.
          this.setLoginState(null);
        }
        return err;
      })
    );

    // const obs = new Observable<User>((observer) => {
    //   // const token = this.storage.retrieve(API_TOKEN);
    //   // if (token) {
    //   //   this.setBearerToken(token, false);
    //   // Hit the API for the current user profile.
    //   this.http
    //     .get(this.baseUrl + `/user/me`, this.getBearerHeaders())
    //     .pipe(take(1))
    //     .subscribe(
    //       (reply: any) => {
    //         const user = reply.me;
    //         this.setLoginState(user);
    //         observer.next(user);
    //       },
    //       (error) => {
    //         if (error && error.status === 401) {
    //           // User is not logged in.
    //           this.setLoginState(null);
    //         }
    //         observer.error(error);
    //       }
    //     );
    //   // } else {
    //   //   observer.error({
    //   //     status: 401,
    //   //     message: 'API token not found',
    //   //   });
    //   //   this.currentUser.next(false);
    //   // }
    // });
    // return obs;
  }

  /**
   * Makes a GET request that returns all the matching documents in the requested sort order.
   * Endpoint names correspond to the properties of the 'entities' object in the apiDefinition.js (line 24)
   *
   * @param dbEndpoint The named CRUD API endpoint
   * @param queryParams The sort and filter parameters
   */
  getDbData(dbEndpoint: string, queryParams?: any): Observable<any> {
    console.log({ gapi });
    const url: string = this.baseUrl + `/db/${dbEndpoint}`;
    const obs = this.getBearerHeaders({ params: queryParams }).pipe(
      mergeMap((headers) => {
        return this.http.get(url, headers);
      })
    );
    return this.handleResponse(obs);
  }

  /**
   * Makes a GET request that returns all the matching documents in the requested sort order.
   * Endpoint names correspond to the properties of the 'entities' object in the apiDefinition.js (line 24)
   *
   * @param dbEndpoint The named CRUD API endpoint
   * @param queryParams The sort and filter parameters
   */
  getLogData(queryParams?: any): Observable<any> {
    const url: string = this.baseUrl + `/log`;

    const obs = this.getBearerHeaders({ params: queryParams }).pipe(
      mergeMap((headers) => {
        return this.http.get(url, headers);
      })
    );
    return this.handleResponse(obs);
  }

  //   /**
  //  * Makes a GET request that returns all the matching documents in the requested sort order.
  //  * Endpoint names correspond to the properties of the 'entities' object in the apiDefinition.js (line 24)
  //  *
  //  * @param dbEndpoint The named CRUD API endpoint
  //  * @param queryParams The sort and filter parameters
  //  */
  // getSocketToken(): Observable<any> {
  //   const url: string = this.baseUrl + `/user/sockettoken`;

  //   const obs = this.http.get(url, {
  //     headers: this.headers,
  //     params: {}
  //   });
  //   return this.handleResponse(obs);
  // }

  /**
   * GET request to retrieve a single document by Id.
   *
   * @param dbEndpoint The CRUD end point in the API.
   * @param id The id of the requested document
   */
  getDbDataById(dbEndpoint: string, id: string, children?: string): Observable<any> {
    const encodedId = encodeURIComponent(id);
    const childrenSegment = children ? `/${children}` : '';
    const url = this.baseUrl + `/db/${dbEndpoint}/${encodedId}${childrenSegment}`;
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.get(url, headers);
      })
    );
    return this.handleResponse(obs);
  }

  /**
   * Delete request to remove the document from the database.
   *
   * @param dbEndpoint  The named CRUD end point in the API.
   * @param id    Unique document identifier
   */
  deleteDbDataById(dbEndpoint: string, id: string): Observable<any> {
    const encodedId = encodeURIComponent(id);
    const url = this.baseUrl + `/db/${dbEndpoint}/${encodedId}`;
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.delete(url, headers);
      })
    );
    return this.handleResponse(obs);
  }

  // insertLog(endpoint: string, doc: any): Observable<any> {
  //   const obs = this.http.post(this.baseUrl + `/hash-log/${endpoint}`, doc, this.getBearerHeaders());
  //   return this.handleResponse(obs);
  // }

  /**
   * Makes a POST request on the endpoint to insert the document.
   * If the document contains a file (binary type in the back-end) then
   * that is submitted on a subsequent PUT request (multi-part form-encoded)
   *
   * @param dbEndpoint The named CRUD end point in the API.
   * @param doc A JSON document to be inserted.
   */
  insert(dbEndpoint: string, doc: any): Observable<any> {
    let fd: FormData;

    // Optionally include the PK column with the endpoint name.
    const parts = dbEndpoint.split('.');
    const endpoint = parts[0];
    const pkColumn = parts[1] || '_id';
    const pkValue = doc[pkColumn];

    for (const key in doc) {
      if (doc[key] && doc[key].constructor) {
        if (doc[key].constructor.name === 'File') {
          if (!fd) {
            fd = new FormData();
          }
          fd.append(key, doc[key]);
        }
      }
    }
    const url = `${this.baseUrl}/db/${endpoint}`;
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(url, doc, headers);
      })
    );

    let final = obs;
    if (fd) {
      final = obs.pipe(
        mergeMap(() => {
          return this.getBearerHeaders().pipe(
            mergeMap((headers) => {
              return this.http.put(`${this.baseUrl}/db/${endpoint}/${pkValue}`, fd, headers);
            })
          );
        })
      );
    }
    return this.handleResponse(final);
  }

  /**
   * Makes a PUT request on the endpoint to update a document.
   * If the document contains a file (binary type in the back-end) then
   * that is submitted on a subsequent PUT request (multi-part form-encoded)
   *
   * @param dbEndpoint The CRUD endpoint in the API.
   * @param doc A JSON document containing values to be updated.
   */
  update(dbEndpoint: string, doc: any): Observable<any> {
    let fd: FormData;
    const parts = dbEndpoint.split('.');
    const endpoint = parts[0];
    const pkColumn = parts[1] || '_id';
    const pkValue = doc[pkColumn];
    const encodedPk = encodeURIComponent(pkValue);

    for (const key in doc) {
      if (doc[key] && doc[key].constructor) {
        if (doc[key].constructor.name === 'File') {
          if (!fd) {
            fd = new FormData();
          }
          fd.append(key, doc[key]);
        }
      }
    }

    const url = `${this.baseUrl}/db/${endpoint}/${encodedPk}`;
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.put(url, doc, headers);
      })
    );

    let final = obs;
    if (fd) {
      final = obs.pipe(
        mergeMap(() => {
          return this.getBearerHeaders().pipe(
            mergeMap((headers) => {
              return this.http.put(url, fd, headers);
            })
          );
        })
      );
    }
    return this.handleResponse(final);
  }

  // ******** CUSTOM Backend FUNCTIONS ******************

  /**
   * Makes a post request to the custom funtion and returns the result.
   *
   * @param functionName The name of the API function to call, as defined in
   * the functions object of apiDefinition.js (line 122)
   * @param body The body to post to the request.
   */
  postPlugin(pluginPrefix: string, functionName: string, body: any): Observable<any> {
    const url = this.baseUrl + `/${pluginPrefix}/${functionName}`;
    // const obs = this.http.post(url, body, this.getBearerHeaders());
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.post(url, body, headers);
      })
    );
    return this.handleResponse(obs);
  }

  /**
   * Makes a get request to the custom function with the supplied query string,
   * and return the response.
   *
   * @param functionName The name of the REST API function to call.
   * @param queryString The query string to apply to the get request.
   */
  getPlugin(pluginPrefix: string, functionName: string, queryString?: string): Observable<any> {
    let url = this.baseUrl + `/${pluginPrefix}/${functionName}`;
    if (queryString) {
      url += `?${queryString}`;
    }
    // const obs = this.http.get(url, this.getBearerHeaders());
    const obs = this.getBearerHeaders().pipe(
      mergeMap((headers) => {
        return this.http.get(url, headers);
      })
    );
    return this.handleResponse(obs);
  }
}
