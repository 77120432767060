import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
// import { AuthorizedTasks } from './models/authorizedTasks';
import { User } from './models/user';
import { TransomApiClientService } from './services/transom-api-client/transom-api-client.service';
import { SettingsService } from './utils/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn: Boolean = false;
  isHandset: Boolean = false;
  currentUser: User | boolean;
  subscriptions: Subscription;
  initialPath: string;

  constructor(
    private apiClient: TransomApiClientService,
    private router: Router,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private settingService: SettingsService
  ) {
    this.subscriptions = new Subscription();

    const sub = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map(result => result.matches))
      .subscribe(handset => {
        this.isHandset = handset;
      });
    this.subscriptions.add(sub);

    const subA = this.apiClient.currentUser.subscribe(user => {
      this.loggedIn = !!user;
      this.currentUser = user;
      if (this.loggedIn) {
        this.subscriptions.add(this.settingService.loadSettings().subscribe());
      }
    });
    this.subscriptions.add(subA);
  }

  ngOnInit() {
    this.initialPath = this.location.path();
  }

  logout() {
    this.apiClient
      .googleLogout()
      .pipe(take(1))
      .subscribe(() => {
        console.log('logged out');
        this.router.navigate(['login']);
      });
    return false;
  }

  // hasTask(taskName: String) {
  //   let t: AuthorizedTasks = taskName as AuthorizedTasks;
  //   return this.apiClient.authorizedTask(t);
  // }

  isActive(paths: Array<string>) {
    const currentUrl = this.router.url + '/';
    for (let i = 0; i < paths.length; i++) {
      const p = `/${paths[i]}/`;
      if (currentUrl.indexOf(p) > -1) {
        return 'isActive';
      }
    }
    return '';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
