<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="'dialog'"
        [mode]="'over'"
        [opened]="false"
        >
      <mat-toolbar>Menu</mat-toolbar>
      <mat-nav-list >
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/']">Home</a>
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/deals']">Deals</a>
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/clients/agents']">Agents</a>
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/clients/brokers']">Brokers</a>
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/templates']">Templates</a>
        <a mat-list-item (click)="drawer.close()" [routerLink]="['/settings']">Settings</a>
        <a mat-list-item (click)="drawer.close() && logout()"><mat-icon>logout</mat-icon>Logout</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary" >
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>BOS Advance</span>
      </mat-toolbar>

      <div class="app-container">
        <router-outlet></router-outlet>
    </div>

    </mat-sidenav-content>
  </mat-sidenav-container>