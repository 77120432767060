import { Deal } from '../models/deal';

export class Mentions {
  static mustacheTrigger() {
    return '{{';
  }

  static mustacheTags() {
    const tags = [
      'agent',
      'broker',
      'deal_address',
      'closing_date',
      'unconditional',
      'agent_net_comm',
      'comm_purchased',
      'purchase_price',
      'due_to_agent',
      'due_to_broker',
      'payment_date',
      'payment_days',
      'contract_date'
    ];
    let counter = 0;
    return tags.map(t => {
      return { id: ++counter, value: `{{${t}}}` };
    });
  }

  static sifTrigger() {
    return '[';
  }

  static sifTags() {
    const tags = ['red', 'blue', 'green'];
    let counter = 0;
    return tags.map(t => {
      return { id: ++counter, value: `${t}]` };
    });
  }

  static sanitize(template) {
    // Remove the &#65279; non-printable chars!
    return template.replace(/\uFEFF/g, '');
  }

  static parseMustache(template, deal: Deal) {
    // Whack the stupid repeat mustache tags.
    template = this.sanitize(template);
    template = template.replace(/data-value="{{\s*([\w\.]+)\s*}}"/g, '');

    const data = this.mustacheReplaceData(deal);

    return template.replace(/{{\s*([\w\.]+)\s*}}/g, (tag, match) => {
      const nodes = match.split('.');
      let current = data;
      let i = 0;
      while (i < nodes.length) {
        try {
          current = current[nodes[i]];
        } catch (e) {
          return '';
        }
        i++;
      }
      return current;
    });
  }

  static mustacheReplaceData(deal: Deal): any {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const retval: any = {};
    const tags = Mentions.mustacheTags();
    tags.forEach(tag => {
      const tval = tag.value.replace('{{', '').replace('}}', '');

      switch (tval) {
        case 'agent':
          retval[tval] = deal.agent;
          break;
        case 'broker':
          retval[tval] = deal.brokerage;
          break;
        case 'deal_address':
          retval[tval] = deal.address;
          break;
        case 'closing_date':
          retval[tval] = deal.closingDate
            ? new Date(deal.closingDate).toLocaleDateString()
            : '';
          break;
        case 'unconditional':
          retval[tval] = deal.isUnconditional ? 'Yes' : 'No';
          break;
        case 'agent_net_comm':
          retval[tval] = deal.agentNetCommission;
          break;
        case 'comm_purchased':
          retval[tval] = deal.commissionPurchased;
          break;
        case 'purchase_price':
          retval[tval] = deal.purchasePrice;
          break;
        case 'purchased_price':
          retval[tval] = deal.purchasePrice;
          break;
        case 'due_to_agent':
          retval[tval] = deal.dueToAgent;
          break;
          case 'due_to_broker':
            retval[tval] = deal.dueToBroker;
            break;  
        case 'payment_date':
          retval[tval] = deal.expectedPaymentDate;
          break;
        case 'payment_days':
          const closing = new Date(deal.closingDate);
          const payment = new Date(deal.expectedPaymentDate);
          const diffDays = Math.round(
            Math.abs((closing.getTime() - payment.getTime()) / oneDay)
          );
          retval[tval] = diffDays.toString();
          break;
        case 'contract_date':
          retval[tval] = new Date().toLocaleDateString();
          break;
        default:
          retval[tval] = tval + ' is not handled yet';
      }
    });
    return retval;
  }
}
