<div mat-dialog-title>{{title}}</div>
<div mat-dialog-content class="msgPanel">
  {{message}}

  <!-- <p class="optionContent" *ngIf="!!option">
    <mat-checkbox [(ngModel)]="option.checked" name="option">{{option.message}}</mat-checkbox>
  </p> -->
</div>
<div mat-dialog-actions align="end">
    <button type="button" mat-raised-button color="secondary" (click)="onCancel()">Cancel</button>
    <button type="button" mat-raised-button color="primary" (click)="onOK()" id="okbtn" cdkFocusInitial>OK</button>
  </div>

