import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Setting } from '../models/setting';
import { TransomApiClientService } from '../services/transom-api-client/transom-api-client.service';

@Injectable({
    providedIn: 'root'
  })
  export class SettingsService  {

    settings: Array<Setting>;

      constructor(private apiClient: TransomApiClientService){
      }

      loadSettings(): Observable<boolean> {

        return this.apiClient.getDbData('setting', {tenantId:"1"}).pipe(
            map(data => {
                this.settings = data;
                return true;
            })
        )
      }

      getSetting(code: string): string {
          const setting = this.settings.find(s => s.code == code);
          if (!setting) {
              throw new Error(`setting with code ${code} could not be found`);
          }
          return setting.value;
      }
  }  