// import { DecimalPipe } from "@angular/common";
import { Component, EventEmitter, Host, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroupDirective, FormGroupName } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';

class ValidationMessages {
  // used for displaying errors.
  tip: string;
  required: boolean;
  min: string;
  max: string;
  maxlength: string;
  minlength: string;
  mask: string;
  onlyFuture: string;
  dupTemplate: string;
  noDup: string;
  toDate: string;
  email: string
  validDate: string;
  validSIN: string;
  lessthan:string;
}

@Component({
  selector: "input-valid",
  templateUrl: "./input-valid.component.html",
  styleUrls: ["./input-valid.component.scss"],
  providers:[FormGroupName]
})
export class InputValidComponent implements OnInit {
  @Input() txBase: string; // Translation base path.
  @Input() tx: string; // Comma separated list of attributes to translate.

  @Input() placeholder: string; // option to manually set the label text
  @Input() name: string; // match the formControl name
  @Input() prefix: string; 
  @Input() suffix: string; 
  @Input() inputType: string = "input"; // input, select, multiselect, textarea, number, (NOT)dollar
  @Input() rows: string = "5"; // textarea rows
  @Input() autoComplete: any;
  // Define Select options
  @Input() options: any[] = [];
  @Input() optionDisplay: string = "name";
  @Input() optionValue: string = "code";

  @Output('change') onChange: EventEmitter<any>;

  @Input('showClear') showClear: boolean;
  @Output('clearClick') onClearClick: EventEmitter<any>;

  // Input-valid attributes  
  required: boolean;

  control: FormControl;
  messages: ValidationMessages;

  nestedformgroup: string;

  constructor(@Host() private formGroup: FormGroupDirective, 
  @Host() private formGroupName: FormGroupName, 
  private translateService: TranslateService,
  // private decimalPipe: DecimalPipe
  ) {
    this.onChange = new EventEmitter<any>();
    this.onClearClick = new EventEmitter<any>();
  }

  ngOnInit() {
    const ix = this.txBase.indexOf(".");
    if (this.txBase && ix > 0){
      this.nestedformgroup = this.txBase.substring(ix + 1);

      if ( this.formGroup.form.controls[this.nestedformgroup] instanceof FormArray){
        if (this.nestedformgroup != this.formGroupName.path[0]){
          throw Error(`Mismatched FormArray: '${this.nestedformgroup}' != '${this.formGroupName.path[0]}'!`);
        }
        const arrayIndex = this.formGroupName.path[1]; 
        const fg = (this.formGroup.form.controls[this.nestedformgroup] as any).controls[arrayIndex];
        this.control = fg.controls[this.name] as any
      } else {
        this.control = (this.formGroup.form.controls[this.nestedformgroup] as any).controls[this.name] as any
      }
    } else {
      this.control = this.formGroup.form.controls[this.name] as any;
    }

    if (!this.control){
      console.log(`Unable to locate control named '${this.name}'.`);
    } 
    // else {
    //   console.log("found control for ", this.name, this.control);
    // }

    // Make sure the optionValue & optionDisplay make sense with our options array data.
    if (this.inputType.indexOf('select') > -1 && (this.options || []).length > 0) {
      const keys = Object.keys(this.options[0]);
      if (!keys.includes(this.optionValue)) {
        console.error(`Input-Valid options for '${this.name}' do not include optionValue attribute '${this.optionValue}'. [${keys.join(', ')}]`);
      }
      if (!keys.includes(this.optionDisplay)) {
        console.error(`Input-Valid options for '${this.name}' do not include optionDisplay attribute '${this.optionDisplay}'. [${keys.join(', ')}]`);
      }
    }

    if (this.name) {
      this.applyTranslations(this.name);
    }
  }

  applyTranslations(controlName) {
    if (!this.txBase) {
      return;
    }

    const tx = (this.tx || "").toLowerCase().split(",");

    // Set input attributes.
    if (tx.includes("label")) {
      this.placeholder = this.translateService.instant(`${this.txBase}.${controlName}.label`);
    }
    if (tx.includes("required")) {
      this.required = true;
    }

    // Translate known validation messages
    this.messages = new ValidationMessages();
    if (tx.includes("min")) {
      this.messages.min = this.translateService.instant(`${this.txBase}.${controlName}.min`);
    }
    if (tx.includes("max")) {
      this.messages.max = this.translateService.instant(`${this.txBase}.${controlName}.max`);
    }
    if (tx.includes("minlength")) {
      this.messages.minlength = this.translateService.instant(`${this.txBase}.${controlName}.minLength`);
    }
    if (tx.includes("maxlength")) {
      this.messages.maxlength = this.translateService.instant(`${this.txBase}.${controlName}.maxLength`);
    }
    if (tx.includes("tip")) {
      this.messages.tip = this.translateService.instant(`${this.txBase}.${controlName}.tip`);
      // console.log("TIP =", this.messages.tip);
    }
    // if (tx.includes("email")) {
    //   this.messages.tip = this.translateService.instant(`${this.txBase}.${controlName}.email`); // should this be .email rather than .tip?
    // }
    if (tx.includes("required")) {
      this.messages.required = this.translateService.instant(`${this.txBase}.${controlName}.required`);
    }
    if (tx.includes("onlyfuture")) {
      this.messages.onlyFuture = this.translateService.instant(`${this.txBase}.${controlName}.onlyFuture`);
    }
    if (tx.includes("nodup")) {
      this.messages.noDup = this.translateService.instant(`${this.txBase}.${controlName}.noDup`);
    }
    if (tx.includes("duptemplate")) {
      this.messages.dupTemplate = this.translateService.instant(`${this.txBase}.${controlName}.dupTemplate`);
    }
    if (tx.includes("todate")) {
      this.messages.toDate = this.translateService.instant(`${this.txBase}.${controlName}.toDate`);
    }
    if (tx.includes("email")) {
      this.messages.email = this.translateService.instant(`${this.txBase}.${controlName}.email`);
    }
    if (tx.includes("validdate")) {
      this.messages.validDate = this.translateService.instant(`${this.txBase}.${controlName}.validDate`);
    }
    // custom validation (See in the markup!)
    if (tx.includes("validsin")) {
      this.messages.validSIN = this.translateService.instant(`${this.txBase}.${controlName}.validSIN`);
    }
    if (tx.includes("lessthan")) {
      this.messages.lessthan = this.translateService.instant(`${this.txBase}.${controlName}.lessthan`);
    }
  }

  validationErrors(validation: string) {
    if (this.isInvalid() && this.control && this.control.errors) {
      const retval = this.control.errors[validation];
      // console.log(' --->',retval);
      return retval;
    }
    return null;
  }

  // Only show the Tip if the control is *not* empty!
  showTip() {
    return true;
  }

  // Don't use this! Formats as text that doesn't persist.
  // *** parseFloat(1,234.06) --> "1" ***
  // dollarFormat(arg) {
  //   let val = '' + arg.control.value;
  //   val = val.replace(',', '');
  //   if (arg.control.value == null || val.trim() == "") {
  //     return;
  //   }
  //   let tmp = this.decimalPipe.transform(val, '1.2-2', 'en-US');
  //   tmp = tmp.replace(',', '');
  //   arg.control.setValue(tmp);
  // }

  /**
   * Used in the template to show/hide validation errors and style/unstyle the label text.
   */
  isInvalid() {
    let invalid = false;
    if (this.control && this.control.invalid && (!this.control.pristine || this.control.touched)) {
      // If a user has touched a field and it's invalid, style the label!
      invalid = true;
    }
    // console.log({invalid});
    return invalid;
  }

  /**
   * Replace interpolated keys from the error into the message.
   *
   * @param err The Validation error object, includes limits as keys
   * @param message The error message including interpolated keys.
   *
   * Example:
   * MaxLength error: { "maxlength": { "requiredLength": 10, "actualLength": 14 } }
   * MaxLength message:  maxLength="First name is longer than the maximum {requiredLength} characters!"
   *
   * Result : First name is longer than the maximum 10 characters!
   */
  dynamicMsg(validation: string): string {
    const err = this.validationErrors(validation);

    // Accomodate minlength/minLength case differences.
    let message;
    switch (validation) {
      case "minlength":
        message = this.messages[validation] || this["minLength"];
        break;
      case "maxlength":
        message = this.messages[validation] || this["maxLength"];
        break;
      default:
        message = this.messages[validation];
        break;
    }

    // If there's an error, fabricate a message.
    if (err) {
      // Saved for debugging validations with interpolated values.
      // console.log('validation=', validation, 'message=', message, "err=", err);
      message = message || "";
      Object.keys(err).map(key => {
        message = message.replace(`\{${key}\}`, err[key]);
      });
    }
    // console.log("Message", message);
    return message;
  }

  handleChange(ev: any){
    this.onChange.emit(ev);
  }

  clearValue(){
    this.onClearClick.emit();
  }
}
